import { ValidationError, useForm } from "@formspree/react";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import freeEmailDomains from "free-email-domains";
import isBusinessEmail from "./is-business-email";

function ContactFormHome({ formPropertyName }) {
	const [state, handleSubmit] = useForm("xpzeyokp");
	const [emailError, setEmailError] = useState("");
	const formGroupStyles = "mb-3 mb-xl-4";
	const formInputStyles = "p-xl-4 p-3 ps-xl-5 ps-3 ";

	const onSubmit = (event) => {
		event.preventDefault();

		const email = event.target.elements["workEmail"].value;

		// Validate the email before submitting
		if (!isBusinessEmail(email)) {
			setEmailError("Please enter a valid business email address.");
			return; // Stop form submission if invalid
		}

		setEmailError(""); // Clear error if valid
		handleSubmit(event); // Submit the form if valid
	};

	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={onSubmit}>
					<Row>
						<Col lg={6}>
							<Form.Group className={`${formGroupStyles}`} controlId="name">
								<Form.Control
									style={{ border: "1px solid #E3E3E3" }}
									required
									name="name"
									placeholder={`Full Name *`}
									type="text"
									className={`${formInputStyles}`}
								/>
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
						</Col>
						<Col lg={6}>
							<Form.Group className={`${formGroupStyles}`} controlId="phone">
								<Form.Control
									style={{ border: "1px solid #E3E3E3" }}
									type="tel"
									name="phone"
									placeholder="Phone"
									className={`${formInputStyles}`}
								/>
							</Form.Group>
							<ValidationError
								prefix="Phone"
								field="phone"
								errors={state.errors}
							/>
						</Col>
					</Row>

					<Form.Group className={`${formGroupStyles}`} controlId="workEmail">
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							required
							name="workEmail"
							type="email"
							placeholder="Work Email *"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="WorkEmail"
						field="workEmail"
						errors={state.errors}
					/>
					<Form.Group className={`${formGroupStyles}`} controlId="company">
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							required
							type="text"
							name="company"
							placeholder="Company *"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="Company"
						field="company"
						errors={state.errors}
					/>

					<Form.Group
						className={`${formGroupStyles}`}
						controlId="numberOfDesks"
					>
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							name="numberOfDesks"
							type="text"
							placeholder="Number Of Desks"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="numberOfDesks"
						field="numberOfDesks"
						errors={state.errors}
					/>
					<Form.Group
						className={`${formGroupStyles}`}
						controlId="idealLocations"
					>
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							name="idealLocations"
							type="text"
							placeholder="Ideal Location(s)"
							value={formPropertyName ? formPropertyName : ""}
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError
						prefix="idealLocations"
						field="idealLocations"
						errors={state.errors}
					/>

					<Form.Group className={`${formGroupStyles}`} controlId="message">
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							name="message"
							placeholder="Message"
							as="textarea"
							rows={3}
							className={`${formInputStyles}`}
						/>
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					<Form.Group className="mb-3" controlId="accept-the-policy">
						<Form.Check
							required
							label={
								<span>
									I accept the{" "}
									<a
										target="_blank"
										rel="noreferrer"
										href="https://app.termly.io/document/privacy-policy/d479506b-530d-4b78-afbf-949d899b4bd5"
									>
										privacy policy
									</a>
								</span>
							}
							className="w-auto"
							type="checkbox"
							name="accept-the-policy"
							value="true"
						/>
					</Form.Group>

					<ValidationError
						prefix="accept-the-policy"
						field="accept-the-policy"
						errors={state.errors}
					/>
					<Button
						size="small"
						className="btn btn-darker-blue text-white mt-3 px-6 rounded-pill w-100 w-lg-auto"
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						SUBMIT
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={onSubmit}>
			<Row>
				<Col lg={6}>
					<Form.Group className={`${formGroupStyles}`} controlId="name">
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							required
							name="name"
							placeholder={`Full Name *`}
							type="text"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
				<Col lg={6}>
					<Form.Group className={`${formGroupStyles}`} controlId="phone">
						<Form.Control
							style={{ border: "1px solid #E3E3E3" }}
							type="tel"
							name="phone"
							placeholder="Phone"
							className={`${formInputStyles}`}
						/>
					</Form.Group>
					<ValidationError prefix="Phone" field="phone" errors={state.errors} />
				</Col>
			</Row>

			<Form.Group className={`${formGroupStyles}`} controlId="workEmail">
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					required
					name="workEmail"
					type="email"
					placeholder="Work Email *"
					className={`${formInputStyles}`}
				/>
				{emailError && (
					<div style={{ color: "red", marginTop: "5px" }}>{emailError}</div>
				)}
			</Form.Group>
			<ValidationError
				prefix="WorkEmail"
				field="workEmail"
				errors={state.errors}
			/>
			<Form.Group className={`${formGroupStyles}`} controlId="company">
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					required
					type="text"
					name="company"
					placeholder="Company *"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError prefix="Company" field="company" errors={state.errors} />

			<Form.Group className={`${formGroupStyles}`} controlId="numberOfDesks">
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					name="numberOfDesks"
					type="text"
					placeholder="Number Of Desks"
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError
				prefix="numberOfDesks"
				field="numberOfDesks"
				errors={state.errors}
			/>
			<Form.Group className={`${formGroupStyles}`} controlId="idealLocations">
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					name="idealLocations"
					type="text"
					placeholder="Ideal Location(s)"
					value={formPropertyName ? formPropertyName : ""}
					className={`${formInputStyles}`}
				/>
			</Form.Group>
			<ValidationError
				prefix="idealLocations"
				field="idealLocations"
				errors={state.errors}
			/>

			<Form.Group className={`${formGroupStyles}`} controlId="message">
				<Form.Control
					style={{ border: "1px solid #E3E3E3" }}
					name="message"
					placeholder="Message"
					as="textarea"
					rows={3}
					className={`${formInputStyles}`}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			<Form.Group className="mb-3" controlId="accept-the-policy">
				<Form.Check
					required
					label={
						<span>
							I accept the{" "}
							<a
								target="_blank"
								rel="noreferrer"
								href="https://app.termly.io/document/privacy-policy/d479506b-530d-4b78-afbf-949d899b4bd5"
							>
								privacy policy
							</a>
						</span>
					}
					className="w-auto"
					type="checkbox"
					name="accept-the-policy"
					value="true"
				/>
			</Form.Group>

			<ValidationError
				prefix="accept-the-policy"
				field="accept-the-policy"
				errors={state.errors}
			/>
			<Button
				size="small"
				className="btn btn-darker-blue text-white mt-3 px-6 rounded-pill w-100 w-lg-auto"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				SUBMIT
			</Button>
		</Form>
	);
}
export default ContactFormHome;
